<template>
    <master-panel>
        <template v-slot:body>
            <v-form v-model="valid">
                <v-row>
                    <v-col cols="12" class="text-center">
                        <upload-avatar
                            @change="avatar = $event"
                            :preview-img="previewImg"
                            ref="avatar"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-text-field
                            label="Nombre"
                            :rules="allRules.name"
                            @change="name = $event"
                            :value="name"
                            outlined dense
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-text-field
                            label="e-mail"
                            :rules="allRules.email"
                            @change="email = $event"
                            :value="email"
                            outlined dense
                            inputMode="email"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-text-field
                            label="RUT"
                            :rules="allRules.rut"
                            @change="rut = $options.filters.rut($event)"
                            :value="rut"
                            outlined dense
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-text-field
                            label="Teléfono"
                            :rules="allRules.phone"
                            @change="phone = $event"
                            :value="phone"
                            outlined dense
                            :prefix="phoneCode"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-text-field
                            label="Sitio web"
                            :rules="allRules.web"
                            @change="web = $event"
                            :value="web"
                            outlined dense
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-text-field
                            label="Link Youtube"
                            :rules="allRules.youtube_link"
                            @change="youtube_link = $event"
                            :value="youtube_link"
                            outlined dense
                            append-icon="mdi-youtube"
                            @click:append="openLink"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:buttons>
            <v-btn color="primary" x-large depressed class="normal-btn" @click="save" :loading="loading">
                Guardar
            </v-btn>
        </template>
    </master-panel>
</template>

<script>
import MasterPanel from "@/components/layouts/MasterPanel";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import {rules} from "@/utils/Rules";
import UploadAvatar from "@/components/common/inputs/UploadAvatar";
import httpService from "@/services/httpService";

export default {
    name: "ProfileEdit",
    components: {UploadAvatar, KimsaTextField, MasterPanel},
    data() {
        return {
            valid: false,
            loading: false,
            avatar: null,
            previewImg: '',
            name: '',
            email: '',
            rut: '',
            phone: '',
            web: '',
            youtube_link: '',
        }
    },
    computed: {
        allRules() {
            return {
                name: [rules.required, rules.minLength(this.name, 3)],
                email: [rules.required, rules.email],
                rut: [rules.required, rules.rut],
                phone: [rules.required],
                web: [],
                youtube_link: []
            }
        },
    },
    mounted() {
        const vm = this
        vm.setDefaults()
    },
    methods: {
        setDefaults() {
            const vm = this
            vm.avatar = null
            vm.previewImg = vm.currentUser?.avatar ? vm.getFilePath(vm.currentUser.avatar.path, vm.currentUser.avatar.name) : ''
            vm.name = vm.currentUser?.name || ''
            vm.email = vm.currentUser?.email || ''
            vm.rut = vm.currentUser?.rut || ''
            vm.phone = vm.currentUser?.phone || ''
            vm.web = vm.currentUser?.web || ''
            vm.youtube_link = vm.currentUser?.youtube_link || ''
        },
        openLink() {
            const vm = this
            if (!vm.youtube_link) return;
            window.open(vm.youtube_link)
        },
        async save() {
            const vm = this
            if (!vm.valid) return vm.toast('Datos incorrectos', 'error')

            vm.loading = true
            let payload = {
                name: vm.name,
                email: vm.email,
                rut: vm.rut.replace(/\./g, '').replace(/-/g, ''),
                phone: vm.phone,
                web: vm.web,
                youtube_link: vm.youtube_link,
                avatar: vm.avatar,
            }

            // console.log('payload', payload)

            let response = await httpService.postFile(`users/${vm.currentUser.id}/edit`, payload)
            // console.log('response save', response)

            vm.loading = false
            if (response.error) return vm.toast(response.error, 'error')
            if (response.data.error) return vm.toast('Datos incorrectos', 'error')
            vm.toast(response.data.message)
            vm.goBack()
        },
    }
}
</script>

<style scoped>

</style>