<template>
    <div>
        <input ref="fileInput" class="d-none" type="file" @change="onFileChange" :accept="mimeTypes"/>
        <v-avatar size="100" color="#cccccc" @click="pickFile" class="pointer overflow-visible" v-ripple>
            <v-img v-if="currentPreviewImg" :src="currentPreviewImg"/>
            <v-icon v-else color="#999999" size="75" v-text="'$user'"/>
            <div class="edit-btn">
                <v-icon color="#FFFFFF" v-text="'$edit'" small style="border-radius: 0;"/>
            </div>
        </v-avatar>
    </div>
</template>

<script>
export default {
    name: "UploadAvatar",
    props: {
        relativePath: {
            type: String,
            default: 'files'
        },
        previewImg: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            file: null,
            currentPreviewImg: '',
            megabyte: 1048576,
            mimeTypes: 'image/*',
            limitSize: 4 * this.megabyte,
        }
    },
    methods: {
        reset() {
            const vm = this
            vm.file = null
            vm.$refs.fileInput.value = null
        },
        pickFile() {
            const vm = this
            vm.$refs.fileInput.click()
        },
        onFileChange(evt) {
            const vm = this
            const file = evt.target.files[0]
            if (!file) return;
            if (file.size > vm.limitSize) return vm.toast(`Tamaño maximo es de 4mb`, 'error')
            vm.file = file
            vm.$emit('change', vm.file)
        },
    },
    watch: {
        previewImg(val) {
            this.currentPreviewImg = val
        },
        file(val) {
            this.currentPreviewImg = val ? URL.createObjectURL(val) : ''
        }
    }
}
</script>

<style scoped>

.edit-btn {
    position: absolute;
    bottom: -10px;
    right: -10px;
    background-color: var(--v-primary-base);
    border: 5px solid #FFFFFF;
    border-radius: 50%;
    padding: 4px;
}

</style>